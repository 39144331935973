import React from "react";

// styles
const classes = {
  footerContainer: 'relative bg-blue-200 pt-8 pb-6',
  footerDiv: 'container mx-auto px-4',
  footerHorizontalRule: 'my-6 border-gray-500',
  footerSummary: 'flex flex-wrap items-center md:justify-between justify-center',
  footerSummaryText: 'w-full md:w-4/12 px-4 mx-auto text-center text-gray-500'
};

export default function Footer() {
  return (
    <React.Fragment>
      <footer className={classes.footerContainer}>
        <div className={classes.footerDiv}>
          <hr className={classes.footerHorizontalRule} />
          <div className={classes.footerSummary}>
            <div className={classes.footerSummaryText}>
            Copyright © {new Date().getFullYear()}{" "}Chad Dundas Smith and Creative Tim     
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}
