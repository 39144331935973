import React from "react";
import { Link } from 'gatsby';

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [isOpen, setisOpen] = React.useState(false);

  function handleClick() {
    setisOpen(!isOpen);
  }

  // const classes = {
  //   checkTransparent: (props.transparent
  //     ? "top-0 absolute z-50 w-full" : "relative shadow-lg bg-white shadow-lg") + " flex flex-wrap items-center justify-between px-2 py-3",
  //     navContents: `container px-4 mx-auto sm:flex flex-wrap items-center justify-between xs: flex-none ${  isOpen ? "block" : "hidden" }`,
  //     navItemContainer: `w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start`,
  //     navItemTitle: (props.transparent ? "text-white" : "text-gray-800") + ` text-xl font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase sm:bg-opacity-0 ${  isOpen ? "bg-gray-900 w-5/12" : "bg-opacity-0" }` 
  // }
  // styles
const classes = {
  checkTransparent: (props.transparent
    ? "top-0 absolute z-50 w-full" : "relative shadow-lg bg-white shadow-lg") + " flex flex-wrap items-center  px-2 py-3",
    navContents: `container px-4 mx-auto sm:flex flex-wrap items-center  xs: flex-none ${  isOpen ? "block" : "hidden" }`,
    navItemContainer: `w-full relative flex  lg:w-auto lg:static lg:block lg:justify-start`,
    navItemTitle: (props.transparent ? "text-white" : "text-gray-800") + ` text-xl font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase sm:bg-opacity-0 ${  isOpen ? "bg-gray-900 w-5/12" : "bg-opacity-0" }` 
}

  return (
    <React.Fragment>
       
      <nav
        className={classes.checkTransparent}
      >
        <button class="transition duration-300 inline-block sm:hidden w-12 h-12 text-gray-100 p-1 border-2 border-gray-100 rounded-lg" onClick={handleClick}>
          <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
        </button>
        <div className={classes.navContents}>
          <Link to="/">
            <div className={classes.navItemContainer}>
              <a
                className={classes.navItemTitle}
              >
                Home
              </a>
              <button
                className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <i
                  className={
                    (props.transparent ? "text-white" : "text-gray-800") +
                    " fas fa-bars"
                  }
                />
              </button>
            </div>
          </Link>
          <Link to="/career">
          <div className={classes.navItemContainer}>
              <a
                className={classes.navItemTitle}
              >
                Career 
              </a>
              <button
                className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <i
                  className={
                    (props.transparent ? "text-white" : "text-gray-800") +
                    " fas fa-bars"
                  }
                ></i>
              </button>
            </div>
          </Link>
          {/* <Link to="/projects">
          <div className={classes.navItemContainer}>
              <a
                className={classes.navItemTitle}
              >
                Projects 
              </a>
              <button
                className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <i
                  className={
                    (props.transparent ? "text-white" : "text-gray-800") +
                    " fas fa-bars"
                  }
                ></i>
              </button>
            </div>
          </Link>
          <Link to="/blog">
          <div className={classes.navItemContainer}>
              <a
                className={classes.navItemTitle}
              >
                Blog 
              </a>
              <button
                className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <i
                  className={
                    (props.transparent ? "text-white" : "text-gray-800") +
                    " fas fa-bars"
                  }
                ></i>
              </button>
            </div>
          </Link> */}
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
              (navbarOpen ? " block rounded shadow-lg" : " hidden")
            }
            id="example-navbar-warning"
          >
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}
