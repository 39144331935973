import React from "react";

// styles
const classes = {
  modalBtn: 'shadow-xl bg-blue-700 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150',
  modalContainer: 'text-left justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none',
  modalDiv: 'relative w-auto my-6 mx-auto max-w-sm',
  modalContent: 'border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none',
  modalHeader: 'flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t',
  exitModalBtn1: 'p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none',
  exitModalIcon1: 'bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none',
  modalBody: 'relative p-6 flex-auto',
  modalFooter: 'flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b',
  exitModalBtn2: 'text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
};

const Modal = ({ linkedin }) => {
    const [showModal, setShowModal] = React.useState(false);
    return (
      <React.Fragment>
        <button
          className={classes.modalBtn}
          type="button"
          onClick={() => setShowModal(true)}
        >
          Get in touch
        </button>
        {showModal ? (
          <React.Fragment>
            <div
              className={classes.modalContainer}
            >
              <div className={classes.modalDiv}>
                {/*content*/}
                <div className={classes.modalContent}>
                  {/*header*/}
                  <div className={classes.modalHeader}>
                    <h3 className="text-2xl font-semibold">
                      How to reach me
                    </h3>
                    <button
                      className={classes.exitModalBtn1}
                      onClick={() => setShowModal(false)}
                    >
                      <span className={classes.exitModalIcon1}>
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className={classes.modalBody}>
                    <p className="text-black mb-4 leading-relaxed">
                     Want to get in touch? Contact me via email or LinkedIn. 
                    </p>
                    <p>
                        Email: &nbsp;
                    <a className="text-decoration: underline" href="mailto:chad@mcds.dev">chad@mcds.dev</a>
                    </p>
                    <p>
                        LinkedIn: &nbsp;
                    <a className="text-decoration: underline" href={linkedin}>Profile</a>
                    </p>
                  </div>
                  {/*footer*/}
                  <div className={classes.modalFooter}>
                    <button
                      className={classes.exitModalBtn2}
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }

  export default Modal;